import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Empty,
  Cell,
  CellGroup,
  Toast,
  Field,
  Stepper,
  Calendar,
  Picker,
  Button,
  Popup,
  Form,
  Tab,
  Tabs,
  Loading,
  Overlay,
} from "vant";
import "amfe-flexible";
let app = createApp(App);
app
  .use(store)
  .use(router)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Stepper)
  .use(Calendar)
  .use(Picker)
  .use(Popup)
  .use(Form)
  .use(Button)
  .mount("#app");
app.use(Toast);
app.use(Empty);
app.use(Tab);
app.use(Tabs);
app.use(Loading);
app.use(Overlay);

import request from "./network";

export default {
    // 登录
    Login: (data) => request({
        url: "/api/h5/login",
        data,
        method: "post"
    }),

    //获取场馆基本信息
    getMuseum:(data)=>request({
        url:'/api/h5/getMuseumBaseInfoById',
        params:data,
        method:'get'
    }),


    //获取时间场次
    getSessionTime:(data)=>request({
        url:'/api/h5/getSessionTimeByCustomization',
        data,
        method:'post'
    }),

    //提交预约
    submit:(data)=>request({
        url:"/api/h5/saveReservationTeam",
        data,
        method:'post'
    }),

    //验证手机号是否需要验证码
    isNeed:(data)=>request({
        url:'/api/h5/isNeedVerification',
        params:data,
        method:'get'
    }),

    //发送验证码
    getCode:(data)=>request({
        url:'/api/h5/getSmsCode',
        params:data,
        method:'get'
    }),

    //获取预约凭证
    getElectronic:(data)=>request({
        url:'/api/h5/getReservationTeamBookResult',
        params:data,
        method:'get'
    }),

    //我的预约记录
    getMyOrder:(data)=>request({
        url:'/api/h5/getMineReservationTeam',
        data,
        method:'post'
    }),

    //我已到馆
    hexiao:(data)=>request({
        url:'/api/h5/arriveMuseum',
        params:data,
        method:'get'
    })
}
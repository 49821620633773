<template>
  <router-view />
</template>

<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f5f5f9;
}
#app {
  font-size: 15px;
}
</style>

<template>
  <div class="container">
    <!-- 头部 -->
    <div
      class="head"
      :style="{
        'background-image': 'url(' + state.prepicFix + museumInfor.pics + ')',
      }"
    >
      <div class="title">
        <img :src="state.localPic + '31.png'" alt="" />
        <span>{{ museumInfor.name }}</span>
      </div>
      <div class="introduce">
        <img :src="state.localPic + '32.png'" alt="" />
        <span>{{ museumInfor.openingInformation }}</span>
      </div>
    </div>

    <!-- 中部 -->
    <div class="body_one">
      <van-cell-group inset style="width: 100%; margin-left: 0">
        <van-cell
          :value="date"
          is-link
          arrow-direction="down"
          @click="showCalender = true"
        >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">预约日期</span>
            <span style="color: #969696; margin-left: 5px">{{
              getZhou(moment(data.date).day())
            }}</span>
          </template>
        </van-cell>

        <van-cell
          :value="data.sessionTime"
          is-link
          arrow-direction="down"
          @click="showPopup"
        >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">入场时间</span>
            <span style="color: #969696; margin-left: 5px"
              >剩余名额{{ data.restNum }}</span
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <!-- 中部第二部分 -->
    <div class="body_two">
      <van-form @submit="submit">
        <van-cell-group inset style="width: 100%; margin-left: 0">
          <!-- 输入任意文本 -->
          <van-field
            :rules="[{ required: true, message: '请输入团队名称' }]"
            v-model="teamName"
            label="团队名称"
            placeholder="请输入团队名称"
            required
          />
          <van-field
            @focus="goScroll"
            v-model="chargeName"
            :rules="[{ required: true, message: '请输入团队负责人' }]"
            label="团队负责人"
            placeholder="请输入负责人姓名"
            required
          />
          <!-- 输入手机号，调起手机号键盘 -->
          <van-field
            @focus="goScroll"
            @update:model-value="isNeedCode"
            :rules="[
              { required: true, message: '请输入手机号' },
              { pattern, message: '请输入正确的手机号' },
            ]"
            v-model="chargePhone"
            type="tel"
            label="联系方式"
            placeholder="请输入负责人手机号"
            required
          />

          <van-field
            @focus="goScroll"
            v-if="isNeedCodeNum"
            v-model="phoneCode"
            center
            clearable
            label="短信验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                color="#03a181"
                @click="sendCode"
                :disabled="timeRun"
                >{{
                  timeRun ? `剩余${s.current.value.seconds}秒` : "发送验证码"
                }}</van-button
              >
            </template>
          </van-field>
          <van-cell title="团队人数">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <van-stepper v-model="num" />
            </template>
          </van-cell>
          <van-field
            @focus="goScroll"
            v-if="isReserve === 1"
            v-model="extraInfo"
            :rows="2"
            autosize
            :rules="[{ required: true, message: '请输入挽联信息' }]"
            label="预留挽联"
            type="textarea"
            required
            placeholder="请输入挽联"
          />
          <div class="red" v-if="isReserve === 1">
            <van-field v-model="shili" label="示例" required readonly />
          </div>
          <div class="red">
            <van-field
              readonly
              autosize
              type="textarea"
              label="注意事项"
              required
              v-model="zhuyi"
            />
          </div>
        </van-cell-group>
        <!-- 底部提交预约 -->
        <div class="footer">
          <div style="width: 65%">
            <van-button round block color="#03a181" native-type="submit">
              提交预约
            </van-button>
          </div>
          <div style="width: 30%" @click="router.push('order_list')">
            <van-button round block type="primary"> 查看记录 </van-button>
          </div>
        </div>
      </van-form>
    </div>
    <!-- 日历选择 -->
    <van-calendar
      v-model:show="showCalender"
      @confirm="confirmDate"
      color="#03a181"
      :min-date="minDate"
      :max-date="maxDate"
    />

    <!-- 时间选择弹出框 -->
    <van-popup
      v-model:show="showTimeChoose"
      position="bottom"
      :style="{ height: '40vh', overflow: 'hidden' }"
    >
      <van-picker
        :default-index="defaultIndex"
        title="选择入场时间"
        :columns="sessionTimeList"
        @confirm="onConfirmSessionTime"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
    <van-overlay :show="showNotice" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="notice_title">
          <img src="http://static.bowu66.com/wenlv/27.png" />
          <span>预约公告</span>
        </div>
        <div
          class="notice_content"
          v-html="museumInfor.visitingInformation"
        ></div>
        <div class="bottom_btn">
          <van-button
            @click.stop="showNotice = false"
            :disabled="kan.current.value.seconds !== 0"
            :color="kan.current.value.seconds !== 0 ? '#999' : '#03a181'"
            round
            type="primary"
            block
            >{{
              kan.current.value.seconds === 0
                ? "我知道了"
                : `请先阅读公告, ${kan.current.value.seconds}秒后可关闭此窗口`
            }}</van-button
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script setup>
import { useCountDown } from "@vant/use";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, reactive, toRefs, onMounted } from "vue";
import api from "../api";
import moment from "moment";
import { Toast } from "vant";

const store = useStore();
const state = store.state;
let pattern = new RegExp("^[1][3-8][0-9]{9}$");
let router = useRouter();

//场馆信息
const museumInfor = reactive({
  name: "",
  openingInformation: "",
  pics: "",
  advanceDays: "",
  mostDays: "",
  visitingInformation: "",
});
function getZhou(num) {
  let zhou = new Map([
    [1, "周一"],
    [2, "周二"],
    [3, "周三"],
    [4, "周四"],
    [5, "周五"],
    [6, "周六"],
    [0, "周日"],
  ]);
  return zhou.get(num);
}
let showNotice = ref(!sessionStorage.getItem("kanguo"));
let kan = useCountDown({
  time: 5 * 1000,
  onFinish() {
    sessionStorage.setItem("kanguo", "1");
  },
});
onMounted(() => {
  kan.start();
});

let defaultIndex = ref(0);
let isNeedCodeNum = ref(false);
const data = reactive({
  extraInfo: "", // 憨憨挽联
  sessionTime: "", //入场时间
  restNum: "", //剩余名额
  teamName: "", //团队名称
  chargeName: "", //领导人名称
  chargePhone: "", //负责人名称
  num: "", //团队人数
  date: moment().format("YYYY-MM-DD"), //日期时间
  showCalender: false, //日历组件是否展示
  showTimeChoose: false, //选择时间组件是否展示
  minDate: new Date(), //提前几天预约
  maxDate: new Date(), //最多预约几天内
  phoneCode: "", //验证码
});
let isReserve = Number(localStorage.getItem("isReserve"));
document.title = isReserve === 1 ? "祭扫预约" : "观影预约";
let shili = "XXX(单位名) 敬挽，烈士永垂不朽";
let zhuyi = `预约限50人，50人以上分时段预约，每批${
  isReserve === 1 ? 150 : 100
}元，现金支付，提前准备团队成员名单，现场提交。`;
let {
  sessionTime, //入场时间
  restNum, //剩余名额
  teamName, //团队名称
  chargeName, //领导人名称
  chargePhone, //负责人名称
  num, //团队人数
  date, //日期时间
  showCalender, //日历组件是否展示
  showTimeChoose, //选择时间组件是否展示
  minDate,
  maxDate,
  extraInfo, //挽联
  phoneCode, //验证码
} = toRefs(data);
//获取场馆信息
api
  .getMuseum({
    museumId: state.museumId,
  })
  .then((res) => {
    Object.assign(museumInfor, res.data);
    let minD = moment().add(res.data.advanceDays, "d").format("YYYY-MM-DD");
    let maxD = moment().add(res.data.mostDays, "d").format("YYYY-MM-DD");
    data.minDate = new Date(minD);
    data.maxDate = new Date(maxD);
  });
//倒计时是否开启的变量
const timeRun = ref(false);
//倒计时默认时间
const s = useCountDown({
  time: 60 * 1000,
  onFinish() {
    timeRun.value = false;
    s.reset();
  },
});

//时间场次
const sessionTimeList = ref([]);
//日历选择确认
const confirmDate = (value) => {
  data.showCalender = false;
  data.date = moment(value).format("YYYY-MM-DD");
  getSessionTime();
};
//时间选择确认
const onConfirmSessionTime = (value) => {
  if (value.restNum === 0) {
    Toast("剩余名额为0, 请重新选择!");
    return;
  }
  data.sessionTime = value.value;
  data.restNum = value.restNum;
  data.showTimeChoose = false;
};

const showPopup = () => {
  data.showTimeChoose = true;
};

//获取时间场次
function getSessionTime() {
  api
    .getSessionTime({
      museumId: state.museumId,
      travelDate: data.date,
    })
    .then((res) => {
      let zhao = res.data.filter((item) => item.restNum);
      let index = res.data.indexOf(zhao[0]);
      defaultIndex.value = index === -1 ? 0 : index;
      let { sessionTime, restNum } = zhao[0] || {};
      console.log(sessionTime, restNum);
      Object.assign(data, { sessionTime, restNum });
      sessionTimeList.value = res.data.map((item) => ({
        value: item.sessionTime,
        restNum: item.restNum,
        text: item.sessionTime + " 剩余名额" + item.restNum + "人",
      }));
    });
}
getSessionTime();
let goScroll = (e) => {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
  if (isAndroid) {
    setTimeout(() => {
      e.target.scrollIntoView();
    }, 200);
  }
};
//提交预约
let submit = () => {
  if (+data.restNum === 0) {
    Toast("剩余名额不足，请更换预约时间");
    return false;
  }
  let info = JSON.parse(sessionStorage.getItem("dingyue"));
  let {
    chargePhone,
    chargeName,
    teamName,
    num,
    sessionTime,
    date,
    extraInfo,
    phoneCode,
  } = data;
  api
    .submit({
      chargePhone,
      chargeName,
      teamName,
      num,
      sessionTime,
      extraInfo: isReserve === 1 ? extraInfo : undefined,
      travelDate: date,
      code: phoneCode,
      appMuseumId: state.museumId,
      ...info,
    })
    .then((res) => {
      Toast("预约成功");
      router.push(`/end?id=${res.data}&jin=1`);
    });
};
//发送验证码
let sendCode = () => {
  timeRun.value = true;
  api.getCode({ phone: data.chargePhone }).then((res) => {
    timeRun.value = true;
    s.start();
  });
};

//判断该手机号是否需要验证码
let isNeedCode = (v) => {
  if (v && pattern.test(v)) {
    api
      .isNeed({
        phone: v,
      })
      .then((res) => {
        isNeedCodeNum.value = res.data;
      });
  }
};
</script>

<style lang="less" scoped>
.move {
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: black;
}
.container {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  //头部
  .head {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #03a181;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // 第一行标题部分
    .title {
      display: flex;
      font-size: 19px;
      font-weight: bold;
      color: white;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
    //第二行介绍
    .introduce {
      display: flex;
      font-size: 8px;
      color: white;
      align-items: center;
      margin-top: 10px;
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }

  // 中部第一部分
  .body_one {
    width: 100%;
    margin-top: 15px;
  }
  //中部第二部分
  .body_two {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 90px;
    //底部提交预约部分
    .footer {
      width: 100%;
      height: 70px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }
}
.wrapper {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 335px;
  .notice_title {
    width: 100%;
    height: 40px;
    background-color: #03a181;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .notice_content {
    width: 90%;
    margin: 15px auto 10px;
    height: 300px;
    max-height: 300px;
    min-height: 100px;
    overflow-y: scroll;
  }
  .bottom_btn {
    padding: 0 10px;
    margin-bottom: 15px;
  }
}
.red {
  overflow: hidden;
  /deep/.van-field__body * {
    color: red;
    overflow: hidden;
  }
}
</style>


import axios from "axios";
import { Toast } from "vant";
import router from "../router";
import store from "../store";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
});
service.interceptors.request.use((config) => {
  config.headers.appId = store.state.appId;
  config.headers.token = localStorage.getItem("token");
  return config;
});
service.interceptors.response.use((res) => {
  const data = res.data;
  if (data.code === 200) {
    return data;
  } else if (data.code === 4001) {
    localStorage.clear("token");
    router.replace("/error");
    return Promise.reject(false);
  } else {
    Toast(data.msg);
    return Promise.reject(new Error(res.message || "Error"));
  }
});

export default service;

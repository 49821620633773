import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import api from "@/api";
const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/order_list",
    name: "order_list",
    component: () => import("../views/my_order.vue"),
  },
  {
    path: "/end",
    name: "End",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/end.vue"),
  },
  {
    path: "/error",
    name: "error",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV !== "production") {
    localStorage.setItem("token", "o-peM55EX2fXF2ZBhzn41GoF4moE");
    next();
  } else {
    let { code, isReserve } = to.query;
    if (isReserve) {
      localStorage.setItem("isReserve", isReserve);
    }
    let token = localStorage.getItem("token");
    let ding = sessionStorage.getItem("dingyue");
    if (to.path !== "/error") {
      if (token) {
        if (to.path === "/end") {
          next();
          return;
        }
        if (ding) {
          next();
        } else {
          let { template_id, action, scene, openid } = to.query;
          if (template_id) {
            sessionStorage.setItem(
              "dingyue",
              JSON.stringify({ templateId: template_id, action, scene, openid })
            );
            next();
          } else {
            window.location.replace(
              `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${store.state.appId}&scene=1002,1003&template_id=xCkWlqbZOMbroJvRzjKFruXkMMYJINlDJV_eKFHyhQs&redirect_url=${store.state.redirect_uri}&reserved=test#wechat_redirect`
            );
          }
        }
      } else {
        if (code) {
          api
            .Login({ code, loginType: "h5", appId: store.state.appId })
            .then((res) => {
              localStorage.setItem("token", res.data);
              window.location.replace(
                `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${store.state.appId}&scene=1002,1003&template_id=xCkWlqbZOMbroJvRzjKFruXkMMYJINlDJV_eKFHyhQs&redirect_url=${store.state.redirect_uri}&reserved=test#wechat_redirect`
              );
            });
        } else {
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${store.state.appId}&redirect_uri=${store.state.redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
          );
        }
      }
    } else {
      next();
    }
  }
});
export default router;

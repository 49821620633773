import { createStore } from "vuex";

export default createStore({
  state: {
    localPic: process.env.VUE_APP_STATIC_IMG,
    prepicFix: process.env.VUE_APP_STATIC_IMG2,
    museumId: process.env.VUE_APP_MUSEUMID,
    appId: "wx311c72ba6ae35d60",
    redirect_uri: encodeURIComponent("http://yjsd.bowu66.com/#/"),
  },
  mutations: {},
  actions: {},
  modules: {},
});
